<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg13">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Dhmisa Dance</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Dhmisa Dance</li>
            </ul>
        </div>
    </div>
</div><br>
<div class="team-area ">
    <div class="container">
        <div class="section-title text-center">
            <h2>Dhmisa Dance</h2>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let item of getdance">
                <div class="team-card">
                    <img src="{{item.image}}" alt="Team Images" style="height: 266px;width: 360px;">
                    <div class="content">
                        <!-- <h3>{{item.vehname}}</h3> -->
                        <br>
                        <div class="row">
                            <div class="col-12">
                                <a class="box-btn text" style="font-size: 18px;  padding: 10px 24px;   background-color: white; 
                                color: black; 
                                border: 2px solid #4CAF50;">
                                    <h6>Group :</h6>{{item.troupname}}
                                </a>
                            </div>
                            <br>
                            <div class="col-8">
                                <a class="box-btn text" style="font-size: 18px;  padding: 10px 24px;   background-color: white; 
                                color: black; 
                                border: 2px solid #4CAF50;">
                                    <h6>Contact :</h6>{{item.troupphnnumber}}
                                </a>
                            </div>
                            <div class="col-4">
                                <a class="box-btn text" style="font-size: 20px; padding: 10px 24px;margin-top: 14px;"
                                    href="tel:+91{{item.troupphnnumber}}">Call</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>