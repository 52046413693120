<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg10">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Gallery</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <!-- <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li> -->
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Gallery</li>
            </ul>
        </div>
    </div>
</div>
<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <!-- <span>Gallery</span> -->
            <h2>Latest Images <b>Post</b></h2>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6"
                *ngFor="let img of gallery| paginate: { itemsPerPage: 6, currentPage: page }">
                <div class="blog-card">
                    <img src="{{img.image}}" (click)="zoom(openmodal,gallery,img)" alt="Images"
                        style="width: 650px; height: 365px;">
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12 text-center">
        <div class="pagination justify-content-center mt-30">
            <pagination-controls (pageChange)="page = $event" responsive="true" previousLabel="<" nextLabel=">">
            </pagination-controls>
        </div>
    </div>
</div>
<ng-template #openmodal style="width: 500px;height:500px;">
    <div class="row" id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <div class="w3-content w3-display-container" class="carousel-inner">

            <div *ngIf="showi" class="carousel-item active">
                <img src="{{modalimage1}}" style="width: 100%; height:auto;" class="d-block w-100" alt="...">
                <button type="button" class="close" aria-hidden="true" (click)="modalDismiss()" class="cross"
                    style="margin-left: 99%;">×</button>
            </div>
            <div class="w3-display-container mySlides" *ngFor="let img of gallery">
                <img src="{{img.image}}" style="width: 100%; height:auto;" class="d-block w-100" alt="...">
                <button type="button" class="close" aria-hidden="true" (click)="modalDismiss()" class="cross"
                    style="margin-left: 99%;">×</button>

            </div>
        </div>
        
    </div>
</ng-template>
<app-footer-style-two></app-footer-style-two>