import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-listing-details',
  templateUrl: './listing-details.component.html',
  styleUrls: ['./listing-details.component.scss']
})
export class ListingDetailsComponent implements OnInit {
  id: any;

  // modalCtrl:any

  page: number = 1;

  gallery: any;

  constructor(private router: ActivatedRoute, private service: ServiceService,private modalCtrl:NgbModal ) {

    this.id = this.router.snapshot.params.id;

  }

  ngOnInit(): void {
    this.getgallery();
  }
  getgallery() {
    this.service.getgallerydata11().subscribe((res: any) => {
      // //console.log(res);
      this.gallery = res.data.filter((data: any) => data.category_id == this.id);
      // //console.log(this.gallery);
    })
  }

  
  modalDismiss() {
    this.modalCtrl.dismissAll()
  }

  showi: boolean = true;

  close() {
    this.showi = false;
  }
  modalimage1: any;
  modalimage: any = [];
  zoom(openmodal:any, image:any, img:any) {
    ////console.log( img);
    this.modalimage1 = img.image;
    ////console.log(this.modalimage1);
    this.modalimage = image[0].image;
    ////console.log( this.modalimage);
    this.showi = true;
    this.modalCtrl.open(openmodal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'l' })
  }
}
