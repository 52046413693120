import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';
import Swal from 'sweetalert2';
declare var Razorpay: any;  //rezorpay 

declare global {
  interface Window {
    PGSecurePay: {
      getOcRequestId:
      (params: { totalAmount: string; currency: string }) => string;
    };
  }
}

@Component({
  selector: 'app-bookingform',
  templateUrl: './bookingform.component.html',
  styleUrls: ['./bookingform.component.scss'],
  providers: [DatePipe]
})
export class BookingformComponent {
  datepicked: any;
  submitform: any;
  submitted = false;
  now: any;
  todate: any;
  hotel_id: any;
  fromdate: any;
  rooms: any;
  viewMode = 'tab1';
  statusClass = 'not-active';
  name: any;
  number: any;
  email: any;
  persons: any;
  childerns: any;
  days: any;
  mymoney: any;
  main_amount: any;
  imagesr: any;
  masterdata: any = [];
  options: any = [];
  hotel_price: any
  show: boolean = false;
  ocRequestId: any



  constructor(private formBuilder: FormBuilder, private router: Router, private service: ServiceService, private route: ActivatedRoute,) {

    this.route.queryParams.subscribe(params => {

      this.fromdate = params['fromdate'];
      this.todate = params['todate'];
      this.hotel_id = params['hotel_id'];
      this.persons = '2';
      this.childerns = '0';
    });
    this.name = localStorage.getItem('name');
    this.number = localStorage.getItem('mobileno');
    this.email = localStorage.getItem('email');
  }

  ngOnInit(): void {
    this.datepicked = this.formBuilder.group({
      fromdate: ['', [Validators.required]],
    })
    this.submitform = this.formBuilder.group({
      fromdate: ['', [Validators.required]],
      todate: ['', [Validators.required]],
      days: ['',],
      name: ['', [Validators.required]],
      // age: ['', [Validators.required]],
      number: ['', [Validators.required, Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      persons: ['', [Validators.required]],
      childerns: [''],
      // taxamount:['']
      // amount: ['', [Validators.required]],
    });
    this.getsinglehotel();
    this.getrooms();
    const datePipe = new DatePipe('en-Us');
    this.now = datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  setActiveClass() {
    this.statusClass = 'active';
  }

  get f() { return this.submitform.controls; }

  numericOnly(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  notallowquotes(event: any): boolean {
    let patt = /^[ a-zA-Z0-9_,/-]*$/;
    let result = patt.test(event.key);
    return result;
  }

  diffdays() {
    if (this.fromdate == undefined) {
      alert('Please select the Check In and Check Out ')
    } else if (this.fromdate == this.todate) {
      alert('Please select the another date')
      this.days = '';
    } else {
      this.persons = '2';
      this.childerns = '0';
      var startDate = new Date(this.fromdate);
      var endDate = new Date(this.todate);

      var Time = endDate.getTime() - startDate.getTime();
      this.days = Time / (1000 * 3600 * 24);
      this.total_amount = (this.days * 1) * (this.hotel_price * 1)
      this.main_amount = (this.days * 1) * (this.hotel_price * 1)
    }
  }

  addq() {
    this.show = false;
    if (this.fromdate == '' || this.fromdate == null || this.fromdate == undefined) {
      alert('Please Select the dates')
    } else {
      this.persons = (this.persons * 1) + 1
      var personamount = (300 * 1) * (this.days * 1);
      this.total_amount = (this.total_amount * 1) + (personamount * 1)
      this.mymoney = (this.total_amount * 1) + (personamount * 1)
    }
  }

  adds() {
    this.show = false;
    if (this.fromdate == '' || this.fromdate == null || this.fromdate == undefined) {
      alert('Please Select the dates')
    } else {
      this.childerns = (this.childerns * 1) + 1
      var childrenamount = (150 * 1) * (this.days * 1);
      this.total_amount = (this.total_amount * 1) + (childrenamount * 1)
      this.mymoney = (this.total_amount * 1) + (childrenamount * 1)
    }
  }

  minus() {
    this.show = false;
    if (this.persons == 2) {
      alert("Availiability of 2 persons")
    } else {
      this.persons = (this.persons * 1) - 1;
      var personamount = (300 * 1) * (this.days * 1);
      this.total_amount = (this.total_amount * 1) - (personamount * 1)
    }
  }

  less() {
    this.show = false;
    if (this.childerns == '0') {
      alert("Availiability of 1 childerns")
    } else {
      this.childerns = (this.childerns * 1) - 1;
      var childrenamount = (150 * 1) * (this.days * 1);
      this.total_amount = (this.total_amount * 1) - (childrenamount * 1)
    }
  }

  taxamount: any;



  getrooms() {
    var data = {
      hotel_id: this.hotel_id
    }
    this.service.gethotelroom(data).subscribe((res: any) => {
      this.rooms = res.data;
      // //console.log(this.rooms)
    })
  }
  hotels: any
  rules: any = []
  overview: any = []
  cancelationpolicy: any = []
  accesability: any = []
  bedpolicy: any = []
  consumptionrules: any = []
  foodarrangements: any = []
  hotel_name: any = []
  total_amount: any = []

  getsinglehotel() {
    var data = {
      hotel_id: this.hotel_id
    }
    this.service.getsinglehotel(data).subscribe((res: any) => {
      this.hotels = res.data;
      this.overview = JSON.parse(this.hotels[0].overview);
      this.rules = JSON.parse(this.hotels[0].rules);
      this.cancelationpolicy = JSON.parse(this.hotels[0].cancelationpolicy);
      this.accesability = JSON.parse(this.hotels[0].accesability);
      this.bedpolicy = JSON.parse(this.hotels[0].bedpolicy);
      this.consumptionrules = JSON.parse(this.hotels[0].consumptionrules);
      this.foodarrangements = JSON.parse(this.hotels[0].foodarrangements);
      this.hotel_name = res.data[0].name;
      this.total_amount = res.data[0].price_perday;
      this.hotel_price = res.data[0].price_perday;


      if (res.data[0].image == null) {
        res.data[0].image = "assets/img/download.png";
      }
      if (res.data[0].image2 == null) {
        res.data[0].image2 = "assets/img/download.png";
      }
      if (res.data[0].image3 == null) {
        res.data[0].image3 = "assets/img/download.png";
      }
      if (res.data[0].image4 == null) {
        res.data[0].image4 = "assets/img/download.png";
      }
      if (res.data[0].image5 == null) {
        res.data[0].image5 = "assets/img/download.png";
      }
      if (res.data[0].image6 == null) {
        res.data[0].image6 = "assets/img/download.png";
      }
      if (res.data[0].image7 == null) {
        res.data[0].image7 = "assets/img/download.png";
      }
      if (res.data[0].image8 == null) {
        res.data[0].image8 = "assets/img/download.png";
      }
      if (res.data[0].image9 == null) {
        res.data[0].image9 = "assets/img/download.png";
      }
      if (res.data[0].image10 == null) {
        res.data[0].image10 = "assets/img/download.png";
      }

      this.masterdata = [res.data[0].image, res.data[0].image2, res.data[0].image3, res.data[0].image4, res.data[0].image5, res.data[0].image6, res.data[0].image7, res.data[0].image8, res.data[0].image9, res.data[0].image10];

      console.log(this.masterdata);

      if (res.status == 200) {
        this.diffdays();
      } else {
        alert('server down')
      }
    },
      error => {
      })
  }



  login: boolean = false;
  checklogin() {
    if ((localStorage.getItem('usr_id')) == null) {
      this.login = false;
    } else {
      this.login = true;
    }
  }
  alert() {
    alert('please Login')
  }
  ////////////////razorpay E:\My Projects\aaraku project\aaraku\website\src\assets\img\logo\Footer-Logo.png
  amount: any;
  grand_total: any;

  rezorpay() {
    if (this.submitform.invalid) {
      alert("please fill the Correct Details");
      return;
    } else {
      this.taxamount = ((this.total_amount / 100) * 9);
      var tax = (this.taxamount * 1) * 2
      this.grand_total = (this.total_amount * 1) + (tax * 1)
      this.show = true
    }
  }



  bookappointment() {
    if ((localStorage.getItem('usr_id')) == null) {
      this.login = false;
      alert('please login to continue');
      this.router.navigate(['/login-register']);
    } else {
      this.login = true;
      this.submitted = true;
      if (this.submitform.invalid) {
        Swal.fire('Please fill the empty Fields')
        return;
      } else {
        this.login = true;
        var data = {
          days: this.submitform.value.days,
          number: this.submitform.value.number,
          email: this.submitform.value.email,
          noofperson: this.submitform.value.noofperson,
          childerns: this.submitform.value.childerns,
          amount: this.grand_total,
          fromdate: this.fromdate,
          todate: this.todate,
          hotel_id: this.hotel_id,
          usr_id: localStorage.getItem('usr_id'),
          name: localStorage.getItem('name'),
        }

        this.service.getrazarpaydetails(data).subscribe((res: any) => {
          console.log(res);
          if (res.status == 200) {

            this.options = {
              "name": "Araku Tourismart",
              "description": "Online Payment",
              "image": "../../assets/img/logo/Footer-Logo.png",
              "order_id": res.orderId.id,
              "handler": (transaction: any, response: any) => {
                this.ticketconfrimrazor(transaction.razorpay_payment_id, "1", res.insertId)
              },
              "prefill": {
                "name": localStorage.getItem('name'),
                "contact": this.submitform.value.number
              },
              "notes": {
                // "address": this.dailypoojaform.value.address,
                // "pooja_name": this.dailypoojaform.value.pooja_name,
                // "surname": this.dailypoojaform.value.surename,
                // "fullname": this.dailypoojaform.value.fullname,
                // "date": this.dailypoojaform.value.poojadate,
                // "number": this.dailypoojaform.value.mobile_number,
                // "amount": this.dailypoojaform.value.amount,
                // "email": this.dailypoojaform.value.email,
                // "gothram": this.dailypoojaform.value.gothram,
              },
              "theme": {
                "color": "#106164"
              },
              modal: {
                ondismiss: () => {
                  // this.cancelled();
                }
              }
            };
            var rzp1 = new Razorpay(this.options);
            rzp1.open();

          }
        })
      }
    }
  }

  ticketconfrimrazor(razorpay_id: any, payment_ind: any, id: any) {
    // this.showSpinner = true;
    var data = {
      razorpay_id: razorpay_id,
      id: id,
      status_ind: payment_ind
    }
    this.service.updateRazarpaydailyStatus(data).subscribe((res: any) => {
      // this.showSpinner = false;
      if (res.status == 200) {
        Swal.fire(
          'Ticket Confrimed .. !',
          'success'
        )
        this.router.navigate(['/profile']);
      }
    },
      error => {
        // this.showSpinner = false;
      })
  }

  ticketconfrim() {
    if ((localStorage.getItem('usr_id')) == null) {
      alert('please login');
      this.router.navigate(['/login-register']);
    } else {

      this.ocRequestId = window.PGSecurePay.getOcRequestId(
        { totalAmount: this.grand_total, currency: 'INR' }
      )

      var data = {
        days: this.submitform.value.days,
        number: this.submitform.value.number,
        email: this.submitform.value.email,
        persons: this.submitform.value.persons,
        childerns: this.submitform.value.childerns,
        amount: this.grand_total,
        fromdate: this.fromdate,
        todate: this.todate,
        hotel_id: this.hotel_id,
        usr_id: localStorage.getItem('usr_id'),
        name: localStorage.getItem('name'),
        ocRequestId: this.ocRequestId
      }
      this.service.getpayment(data).subscribe((res: any) => {
        window.open(res.data.data.redirectUrl, "_self");
      })
    }
  }


  activeTab: number = 1;

  activateTab(tabNumber: number) {
    this.activeTab = tabNumber;
  }



}

