<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div *ngFor="let y of hotels">
    <div class="inner-banner inner-bg2" style="background-image: url({{y.image}});">
        <div class="container">
            <div class="inner-content">
                <div class="row align-items-center">
                    <div class="col-lg-8">
                        <div class="inner-banner-text">
                            <h3>{{y.name}}</h3>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="banner-rating">
                            <ul>
                                <li class="rating-star">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <span>4 Review</span>
                                </li>
                                <li><a>4.9</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-bottom">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="banner-tag">
                            <ul>
                                <li style="color: aliceblue;"><a><i class="flaticon-favorite"></i>Book Now</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="banner-profile">
                            <div class="banner-status">
                                <a>
                                    <h3>Open Now</h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-list">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-7">
                        <ul class="list">
                            <li><a href="index.html">Home</a></li>
                            <li><i class='bx bx-chevron-right'></i></li>
                            <li class="active">{{y.name}}</li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-5">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="listing-details-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="listing-details-wrap">
                        <div class="listing-widget listing-widget-desc">
                            <div class="listing-features">
                                <h3 class="features-title">Listing Features</h3>
                                <div class="row">
                                    <div class="col-lg-3 col-md-3">
                                        <div class="features-card">
                                            <i class="flaticon-coffee-cup"></i>
                                            <h3>Restaurant Inside</h3>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3">
                                        <div class="features-card">
                                            <i class="flaticon-wifi"></i>
                                            <h3>Free WiFi</h3>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3">
                                        <div class="features-card">
                                            <i class="flaticon-parking"></i>
                                            <h3>Free Parking</h3>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3">
                                        <div class="features-card">
                                            <i class="flaticon-bread"></i>
                                            <h3>Breakfast</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="test">
                            <div class="listing-widgetss">
                                <h3 class="title">Gallery / Photos</h3>
                                <owl-carousel-o>
                                    <ng-container *ngFor="let x of masterdata">
                                        <ng-template carouselSlide>
                                            <img src="{{x}}" alt="" style="height:250px">
                                        </ng-template>
                                    </ng-container>
                                </owl-carousel-o>
                            </div>
                        </div>
                        <div class="listing-widget-contact">
                            <div class="listing-contact-title">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="listing-widget-mail">
                                            <i class="flaticon-email-2"></i>
                                            <div class="content">
                                                <h3>Don’t Worry</h3>
                                                <span>Your Booking Details is safe with us.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 text-end">
                                        <div class="listing-contact-review">
                                            <ul>
                                                <li class="rating-star">
                                                    <i class='bx bxs-star'></i>
                                                    <i class='bx bxs-star'></i>
                                                    <i class='bx bxs-star'></i>
                                                    <i class='bx bxs-star'></i>
                                                    <i class='bx bxs-star'></i>
                                                    <span>Your Score</span>
                                                </li>
                                                <li><a style="color:white">4.5</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="listing-details-side">
                        <div class="listing-widget-side">
                            <h3 class="title" style="text-align: center;"><b>Booking Ticket</b></h3>
                            <div class="listing-Booking-form">
                                <form [formGroup]="submitform">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <label>From Date</label>
                                            <div class="form-group">
                                                <i class="flaticon-calendar"></i>
                                                <input type="Date" [(ngModel)]="fromdate" [min]="now"
                                                    onkeydown="return false" class="form-control"
                                                    formControlName="fromdate" placeholder="Date & Time">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <label>To Date</label>
                                            <div class="form-group">
                                                <i class="flaticon-calendar"></i>
                                                <input type="Date" [(ngModel)]="todate" min="{{fromdate}}"
                                                    onkeydown="return false" (change)="diffdays()" class="form-control"
                                                    formControlName="todate" placeholder="Date & Time">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <label>No of Days</label>
                                            <div class="form-group">
                                                <i class='bx bx-user'></i>
                                                <input type="text" readonly formControlName="days" class="form-control"
                                                    required placeholder="No.Of Days" [(ngModel)]="days">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <i class='bx bx-user'></i>
                                                <input type="text" formControlName="name" class="form-control" required
                                                    placeholder="Your Name" [(ngModel)]="name">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <i class='flaticon-email-2'></i>
                                                <input type="email" name="email" formControlName="email"
                                                    [(ngModel)]="email" class="form-control" required
                                                    placeholder="E-mail*">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <i class='bx bx-phone'></i>
                                                <input type="text" [(ngModel)]="number" formControlName="number"
                                                    name="phone_number" id="phone_number" required class="form-control"
                                                    placeholder="Your Phone" (keypress)="numericOnly($event)"
                                                    maxlength="10" minlength="10">
                                            </div>
                                        </div>
                                        <div class="add-counter">
                                            <div class="row" style="text-align: center;">
                                                <div class="col-lg-6">
                                                    <h3>No of Adults</h3>
                                                </div>
                                                <div class="col-lg-6">
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <button
                                                                    style="background-color:#106164;color: aliceblue;"
                                                                    (click)="minus()" class="btn"><i
                                                                        class='bx bx-minus'></i></button>
                                                            </td>
                                                            <td>
                                                                <input style="text-align: center;" class="form-control"
                                                                    type="text" min="1" formControlName="persons"
                                                                    [(ngModel)]="persons" value="1">
                                                            </td>
                                                            <td>
                                                                <button
                                                                    style="background-color:#106164;color: aliceblue;"
                                                                    (click)="addq()" class="btn"><i
                                                                        class='bx bx-plus'></i></button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="add-counter">
                                            <div class="row" style="text-align: center;">
                                                <div class="col-lg-6">
                                                    <h3>No of Childern</h3>
                                                </div>
                                                <div class="col-lg-6">
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <button
                                                                    style="background-color:#106164;color: aliceblue;"
                                                                    (click)="less()" class="btn">
                                                                    <i class='bx bx-minus'></i>
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input style="text-align: center;" type="text"
                                                                    class="form-control" min="0"
                                                                    formControlName="childerns" [(ngModel)]="childerns"
                                                                    value="0">
                                                            </td>
                                                            <td>
                                                                <button
                                                                    style="background-color:#106164;color: aliceblue;"
                                                                    (click)="adds()" class="btn">
                                                                    <i class='bx bx-plus'></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="add-counter">
                                            <h3>No of Childern</h3>
                                            <div class="form-input-counter">
                                                <span class="minus-btn"><i (click)="less()"
                                                        class='bx bx-minus'></i></span>
                                                <input type="text" min="0" formControlName="childerns"
                                                    [(ngModel)]="childerns" value="0">
                                                <span class="plus-btn"><i (click)="adds()"
                                                        class='bx bx-plus'></i></span>
                                            </div>
                                        </div> -->
                                    </div>
                                </form>
                                <h3 class="price-rate">Total Cost <span>Rs . {{total_amount}}</span></h3>
                                <div class="col-lg-12 col-md-12 text-center" *ngIf="!show">
                                    <button type="submit" *ngIf="login" (click)="alert()"
                                        class="default-btn border-radius"> Confirm </button>
                                    <button type="submit" *ngIf="!login" (click)="rezorpay()"
                                        class="default-btn border-radius">Confirm</button>
                                </div>
                                <div *ngIf="show">
                                    <h3 class="price-rate">S-GST<span>Rs . {{taxamount | number: '1.0-0' }}</span></h3>
                                    <h3 class="price-rate">C-GST<span>Rs . {{taxamount | number: '1.0-0'}}</span></h3>
                                    <h3 class="price-rate">Grand-total<span>Rs . {{grand_total | number:
                                            '1.0-0'}}</span></h3>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center" *ngIf="show">
                                    <button type="submit" (click)="ticketconfrim()"
                                        class="default-btn border-radius">Pay Online</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">

                        <div class="tabs" style="overflow: auto;">
                            <button (click)="activateTab(1)" [class.active]="activeTab === 1"> Over-view</button>
                            <button (click)="activateTab(2)" [class.active]="activeTab === 2">Rules </button>
                            <button (click)="activateTab(3)" [class.active]="activeTab === 3"> Cancellation Policy
                            </button>
                            <button (click)="activateTab(4)" [class.active]="activeTab === 4">Accessibility </button>
                            <button (click)="activateTab(5)" [class.active]="activeTab === 5">Bed Policy </button>
                            <button (click)="activateTab(6)" [class.active]="activeTab === 6"> Consumption Rules
                            </button>
                            <button (click)="activateTab(7)" [class.active]="activeTab === 7">Food Arrangements
                            </button>
                        </div>

                        <div class="tab-content" *ngIf="activeTab === 1">
                            <p style="text-align: center;" *ngIf="overview.length==0"> No Details Found </p>
                            <ul>
                                <li *ngFor="let y of overview">
                                    {{y.overview}}
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content" *ngIf="activeTab === 2">
                            <p style="text-align: center;" *ngIf="rules.length==0"> No Details Found </p>
                            <ul>
                                <li *ngFor="let y of rules">
                                    {{y.rules}}
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content" *ngIf="activeTab === 3">
                            <p style="text-align: center;" *ngIf="cancelationpolicy.length==0"> No Details Found </p>
                            <ul>
                                <li *ngFor="let y of cancelationpolicy">
                                    {{y.cancelationpolicy}}
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content" *ngIf="activeTab === 4">
                            <p style="text-align: center;" *ngIf="accesability.length==0"> No Details Found </p>
                            <ul>
                                <li *ngFor="let y of accesability">
                                    {{y.accesability}}
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content" *ngIf="activeTab === 5">
                            <p style="text-align: center;" *ngIf="bedpolicy.length==0"> No Details Found </p>
                            <ul>
                                <li *ngFor="let y of bedpolicy">
                                    {{y.bedpolicy}}
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content" *ngIf="activeTab === 6">
                            <p style="text-align: center;" *ngIf="consumptionrules.length==0"> No Details Found </p>
                            <ul>
                                <li *ngFor="let y of consumptionrules">
                                    {{y.consumptionrules}}
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content" *ngIf="activeTab === 7">
                            <p style="text-align: center;" *ngIf="foodarrangements.length==0"> No Details Found </p>
                            <ul>
                                <li *ngFor="let y of foodarrangements">
                                    {{y.foodarrangement}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer-style-two></app-footer-style-two>