<footer class="footer-area footer-bg2">
    <div class="footer-middle ">
        <div class="container">
            <div class="row">
                <div class="container">
                    <div class="row">
                        <div class=" col-sm-12">
                            <h2 class="widget-title" style="text-align: center; color: beige;">Araku Tourismart</h2>
                            <ul style="text-align:center">
                                <li style="list-style-type: none; display: inline-block;padding:1%;">
                                    <a a routerLink="/home-one" style="color:white">Home &nbsp;&nbsp;&nbsp;&nbsp; |</a>
                                </li>
                                <li style="list-style-type: none; display: inline-block;padding:1%;">
                                    <a a routerLink="/listing" style="color:white"> Gallery &nbsp;&nbsp;&nbsp;&nbsp;
                                        |</a>
                                </li>
                                <li style="list-style-type: none; display: inline-block;padding:1%;">
                                    <a a routerLink="/team" style="color:white"> Hotel Bookings &nbsp;&nbsp;&nbsp;&nbsp;
                                        |</a>
                                </li>
                                <li style="list-style-type: none; display: inline-block;padding:1%;">
                                    <a a routerLink="/contact" style="color:white"> Contact&nbsp;&nbsp;&nbsp;&nbsp;
                                        |</a>
                                </li>
                                <div class="col-sm-12">
                                    <h4 style="color:aliceblue;text-align: center;"> Website Visitors :<a
                                            href="https://www.freecounterstat.com" title="page hit counter"><img
                                                src="https://counter10.optistats.ovh/private/freecounterstat.php?c=uce3xuttnemdywbh9msyw3e33jxxra6h"
                                                border="0" title="page hit counter" alt="page hit counter"></a>
                                    </h4>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="copy-right-area copy-right-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="copy-right-list">
                    <ul>
                        <li><a routerLink="/terms-condition">Terms of Use</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/cancellation">Cancellation</a></li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</div>



<!-- <footer class="sigma_footer footer-2" style="color:white;position: inherit;">
    <div class="sigma_footer-middle">
        <div class="container">
            <div class="row">                
                <div class="col-xl-12 col-lg-2 col-md-4 col-sm-12">
                    <h5 class="widget-title" style="text-align: center;">Sri Venkateswara Swamy Vaari Devasthanam</h5>
                    <h5 class="widget-title" style="text-align: center;">శ్రీ వేంకటేశ్వర స్వామి వారి దేవస్థానం</h5>
                    <ul style="text-align:center">
                        <li style="list-style-type: none; display: inline-block;padding:1%;">
                            <a routerLink="/" style="color:white">Home &nbsp;&nbsp;&nbsp;&nbsp; |</a>
                        </li>
                        <li style="list-style-type: none; display: inline-block;padding:1%;">
                            <a routerLink="/gallery" style="color:white"> Gallery &nbsp;&nbsp;&nbsp;&nbsp; |</a>
                        </li>
                        <li style="list-style-type: none; display: inline-block;padding:1%;">
                            <a routerLink="/pujas" style="color:white"> Poojas &nbsp;&nbsp;&nbsp;&nbsp; |</a>
                        </li>
                        <li style="list-style-type: none; display: inline-block;padding:1%;">
                            <a routerLink="/devoteespeak"  style="color:white"> Devotee Speaks &nbsp;&nbsp;&nbsp;&nbsp; |</a>
                        </li>
                        <li style="list-style-type: none; display: inline-block;padding: 1%;">
                            <a routerLink="/templehistory" style="color:white"> Temple History &nbsp;&nbsp;&nbsp;&nbsp; |</a>
                        </li>
                        <li style="list-style-type: none; display: inline-block;padding: 1%;">
                            <a routerLink="/upalayas" style="color:white"> Upalaya's </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  
    <div class="sigma_footer-bottom">
        <div class="container-fluid">
            <div class="sigma_footer-copyright">
                <p style="color:white">Designed And Developed By <a href="https://www.amaravathisoftware.com/"
                        class="custom-primary"> <span style="color:rgb(39, 125, 201)">@Amaravathi Software innovations
                            pvt.ltd</span></a> </p>
            </div>
            <div class="sigma_footer-logo">
                <img src="https://vsvstemple.com/websiteimages/logo.png" alt="logo">
            </div>
            <ul class="sigma_sm square">
                <li>
                    <a href="#">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fab fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fab fa-youtube"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</footer> -->