<app-navbar-style-one></app-navbar-style-one>
<br><br><br><br>
<div class="inner-banner inner-bg15">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Destinations</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Destinations</li>
            </ul>
        </div>
    </div>
</div>
<br><br>
<div class="team-area ">
    <div class="container">
        <div class="row" id="container" *ngFor="let item of finalarray">
            <div class="col-lg-6">
                <div class="product-image">
                    <img src="{{item.image}}" alt="">
                    <div class="info">
                        <h2> Description</h2>
                        <ul>
                            <!-- <li *ngFor="let i of item.description"> -->
                            <li style="overflow: y;">
                                <strong>{{item.description}}</strong>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="product-details">
                    <h1>{{item.name}}</h1>
                    <br>
                    <div class="persuasion__item pc__peitho">
                        <i class="fa fa-question-circle-o" aria-hidden="true">

                        </i> <span style="text-align: justify;">
                            {{item.subtitle}}</span>
                    </div>
                    <p class="information"> <i class="fa fa-compass" aria-hidden="true"></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;{{item.time}}</p>
                    <div class="control">
                        <button class="btn">
                            <span style="color: black;">{{item.price }}</span><br><br>
                             <span class="buy"> <a target="_blank" href="{{item.locationurl}}">Location</a></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br><br>
<app-footer-style-two></app-footer-style-two>