<app-navbar-style-one></app-navbar-style-one>
<br><br><br><br>
<div class="inner-banner inner-bg8">
    <div class="container">
        <div class="inner-title text-center">

        </div>
    </div>
</div><br>

<div class="container">
    <div class="row ">
        <div class="col-lg-4">
            <div class="team-card" (click)="formshow(1)">
                <img src="assets/img/place-list/p1.png">
            </div>
        </div>
        <div class="col-lg-4">
            <div class="team-card" (click)="formshow(2)">
                <img src="assets/img/place-list/p4.png">
            </div>
        </div>
        <div class="col-lg-4">
            <div class="team-card" (click)="formshow(3)">
                <img src="assets/img/place-list/p2.png">
            </div>
        </div>
    </div>
</div>


<div class="container">
    <div>
        <div *ngIf="form_ind==1">
            <h2 class="kasi">Couple Packages</h2>
            <div class="row">
                <div class="col-sm-12 col-lg-4 " style="padding-bottom:2%">
                    <div class="card"
                        style="background-image: linear-gradient(to bottom right,#8d9091,#2a4562);padding: 8%;">
                        <h2
                            style="color: aliceblue;text-align: center; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
                            Most Reasonable </h2>
                        <span style="color:white;font-size:80%"><b>STARTING AT</b></span>
                        <div class="links">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius"> Rs. {{package[0].price}} /-</button>
                                </div>
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius">
                                        <a style="color:rgb(255, 255, 255);font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;"
                                            (click)="submit('Couple' ,'Most Reasonable')">REQUEST
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-4" style="padding-bottom:2%">
                    <div class="card"
                        style="background-image: linear-gradient(to bottom right,#b18cf2,#0d0c10);    padding: 8%;">
                        <h2
                            style="color: aliceblue;text-align: center; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
                            Budget package</h2>
                        <span style="color:white;font-size:80%"><b>STARTING AT</b></span>
                        <div class="links">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius"> Rs. {{package[1].price}} /-</button>
                                </div>
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius"><a
                                            style="color:rgb(255, 255, 255);font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;"
                                            (click)="submit('Couple' ,'Budget package')">REQUEST</a></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-4" style="padding-bottom:2%">
                    <div class="card"
                        style="background-image: linear-gradient(to bottom right,#c1bcca,#35206b);    padding: 8%;">
                        <h2
                            style="color: aliceblue;text-align: center; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
                            Premium package </h2>
                        <span style="color:white;font-size:80%"><b>STARTING AT</b></span>
                        <div class="links">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius"> Rs.{{package[2].price}}  /-</button>
                                </div>
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius"><a
                                            style="color:rgb(255, 255, 255);font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;"
                                            (click)="submit('Couple' ,'Premium package')">REQUEST
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="form_ind==2">
            <h2 class="kasi">Group Packages</h2>
            <div class="row">
                <div class="col-sm-12 col-lg-4" style="padding-bottom:2%">
                    <div class="card"
                        style="background-image: linear-gradient(to bottom right,#8d9091,#2a4562);    padding: 8%;">
                        <h2
                            style="color: aliceblue;text-align: center;  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
                            Most Reasonable </h2>
                        <span style="color:white;font-size:80% "><b>STARTING AT</b></span>
                        <div class="links">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius">Rs. {{package[3].price}}  /-</button>
                                </div>
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius">
                                        <a style="color:rgb(255, 255, 255); font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;"
                                            (click)="submit('Bulk', 'Bulk package')">REQUEST
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="form_ind==3">
            <h2 class="kasi">Families & Friends Package</h2>
            <div class="row">
                <div class="col-lg-4 col-sm-12" style="padding-bottom:2%">
                    <div class="card"
                        style="background-image: linear-gradient(to bottom right,#8d9091,#2a4562);    padding: 8%;">
                        <h2 style="color: aliceblue;text-align: center; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
                            Upto 6 Memebers</h2>
                        <span style="color:white;font-size:80% "> <b>STARTING AT</b></span>
                        <div class="links">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius"> Rs.{{package[4].price}} /-</button>
                                </div>
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius">
                                        &nbsp;&nbsp;<a
                                            style="color:rgb(255, 255, 255); font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;"
                                            (click)="submit('family & friends' ,'Upto 6 Members package')">REQUEST</a></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-4" style="padding-bottom:2%">
                    <div class="card"
                        style="background-image: linear-gradient(to bottom right,#c1bcca,#35206b);    padding: 8%;">
                        <h2  style="color: aliceblue;text-align:center; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">  Upto 4 Members</h2>
                        <span style="color:white;font-size:80%"><b>STARTING AT</b></span>
                        <div class="links">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius"> Rs. {{package[5].price}}  /-</button>
                                </div>
                                <div class="col-lg-6">
                                    <button type="submit" class="default-btn border-radius"><a
                                            style="color:rgb(255, 255, 255); font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif "
                                            (click)="submit('family & friends' ,'Upto 4 Members package')">REQUEST</a></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<br><br>

<app-footer-style-two></app-footer-style-two>