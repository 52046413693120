import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/pages/service.service';

@Component({
  selector: 'app-footer-style-two',
  templateUrl: './footer-style-two.component.html',
  styleUrls: ['./footer-style-two.component.scss']
})
export class FooterStyleTwoComponent implements OnInit {
  count: any;

  constructor(private service: ServiceService,) { }

  ngOnInit(): void {
    //  this.getcount();
  }

  getcount() {
    this.service.getcountapi().subscribe((res: any) => {
      this.count = res.value;
    })
  }






}
