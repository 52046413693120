import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/pages/service.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  about: any;
  title: any;

  constructor(private service: ServiceService) { }

  ngOnInit(): void {
    this.getabout();
  }

  getabout() {
    this.service.getabout().subscribe((res: any) => {
      this.about = res.data;
      this.title = this.about[0].title
    })
  }

}
