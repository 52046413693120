import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paymentstatus',
  templateUrl: './paymentstatus.component.html',
  styleUrls: ['./paymentstatus.component.scss']
})

export class PaymentstatusComponent {

  unique_id: any;
  customerdetails: any;
  paymentdetails: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private service: ServiceService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.unique_id = params['paymentid'];

      // setTimeout(() => {
      // }, 5000)
    });
    this.getdetails();

  }

  getdetails() {
    var data = {
      unique_id: this.unique_id
    }
    this.service.getpaymnetdetials(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.customerdetails = res.data[0];
        this.checkstatus();
      } else {
        Swal.fire("Payment Failed")
      }
    })
  }

  checkstatus() {
    var data = {
      g_id: this.customerdetails.g_id
    }
    this.service.checkstatus(data).subscribe((res: any) => {
      this.paymentdetails = res.data;

      setTimeout(() => {
        console.log("hitted");

        window.location.href = 'arakutourismart://home/myticketpage'
      }, 5000)

    })
  }













}
