<app-navbar-style-one></app-navbar-style-one>

<br><br><br>
<div class="inner-banner inner-bg14">
    <div class="container">
        <div class="inner-title text-center">
            
        </div>
    </div>
</div><br>
<div class="about-area ">
    <div class="container">
        <h2 style="text-align: center;
        color: #328780;
        font-family: none;
    ">Camping Tents</h2>
        <ul>
            <li class="booking-card" *ngFor="let item of camping" style="background-image: url({{item.image}})">
                <div class="book-container">
                    <div class="content">
                        <button class="btn">Thank You</button>
                    </div>
                </div>
                <div class="informations-container">
                    <h2 class="title">{{item.tentname}}</h2>
                    <p class="price"><svg class="icon" style="width:24px;height:24px">
                        </svg> Price: {{item.price}}/-</p>
                    <div class="more-information">
                        <div class="info-and-date-container">
                            <div class="box info">
                                <svg class="icon" style="width:24px;height:24px">
                                </svg>
                                <img src="assets/img/blog/images.png"
                                    style="height: 40px; width: 40px;margin-left: -23px;">
                                <a class="box-btn text" style="font-size: 20px; padding: 10px 24px;"
                                    href="tel:+91{{item.phonenumber}}">Call</a>
                            </div>
                            <div class="box date">
                                <svg class="icon" style="width:24px;height:24px">
                                </svg>
                                <img src="assets/img/blog/Google_Maps_icon_(2020).svg.png"
                                    style="height: 40px; width: 30px;margin-left: -23px;">
                                <button class="btn">
                                    <a class="box-btn text" style="font-size: 20px;" href="{{item.location}}">Maps</a>
                                </button>
                            </div>
                        </div>
                        <p class="disclaimer">"Money can not buy happiness but it can buy a tent and that's kinda the
                            same thing."</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

<br><br><br><br>
<app-footer-style-two></app-footer-style-two>