import { Component } from '@angular/core';
import { ServiceService } from 'src/app/pages/service.service';

@Component({
  selector: 'app-bestway',
  templateUrl: './bestway.component.html',
  styleUrls: ['./bestway.component.scss']
})
export class BestwayComponent {

  about: any;
  title: any;

  constructor(private service: ServiceService) { }

  ngOnInit(): void {
    this.getabout();
  }

  getabout() {
    this.service.getabout().subscribe((res: any) => {
      this.about = res.data;
      this.title = this.about[0].title
    })
  }
}
