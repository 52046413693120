<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg8">
    <div class="container">
        <div class="inner-title text-center">
        </div>
    </div>
</div><br>
<div class="team-area ">
    <div class="contact-map">
        <div class="contact-form">
            <h2 style="text-align: center;">Search The vehicles</h2>
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <label for="exampleInputPassword1">Date :</label>
                            <input type="date" name="name" id="name" class="form-control" formControlName="date"
                                [min]="now" required placeholder=" Date*">
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <label for="exampleInputPassword1">From :</label>
                            <select class="form-control s" placeholder="Select state" formControlName="from">
                                <!-- <option value='' disabled aria-selected="true">Select Start Point</option> -->
                                <option value='Visakhapatnam' selected>visakhapatnam</option>
                                <!-- <option value='Araku'>Araku</option> -->
                                <!-- <option value='sightseeing'>Local Sight Seeing</option> -->
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <label for="exampleInputPassword1">To :</label>
                            <select class="form-control s" placeholder="Select state" formControlName="to">
                                <!-- <option value='' disabled aria-selected="true">Select End Point</option>
                                <option value='Visakhapatnam'>visakhapatnam</option> -->
                                <option value='Araku' selected>Araku</option>
                                <!-- <option value='sightseeing'>Local Sight Seeing</option> -->
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <label for="exampleInputPassword1">No.Of Persons</label>
                            <input id="password_field" formControlName="noofperson" type="tel"
                                (keypress)="numericOnly($event)" numericOnly title="Inpit title" class="form-control">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-4">
                                <label for="html">Hatch Back</label><br>
                                <input type="radio" value="1" formControlName="cartype">
                            </div>
                            <div class="col-4">
                                <label for="html">Sedan</label><br>
                                <input type="radio" value="2" formControlName="cartype">
                            </div>
                            <div class="col-4">
                                <label for="html">Suv</label><br>
                                <input type="radio" value="3" formControlName="cartype">
                            </div>
                            <!-- <div class="col-3">
                                <label for="html">Auto</label><br>
                                <input type="radio" value="4" formControlName="cartype">
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12" style="text-align: center;">
                        <button type="submit" class="default-btn border-radius" (click)="getItems();">Search</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="container">
            <div class="row pt-45">
                <div class="col-lg-4 col-md-6" *ngFor="let item of getallviechle">
                    <div class="team-card">
                        <img src="{{item.image}}" alt="Team Images" style="height: 266px;width: 360px;">
                        <div class="content">
                            <h3>{{item.vehname}}</h3>
                            <br>
                            <h4 style="font-size: 18px;padding: 10px 24px;background-color: white; 
                            color: black;border: 2px solid #4CAF50;text-align: center;"> Rs. {{item.price}} Per KM</h4>
                            <div class="row">
                                <div class="col-8">
                                    <a class="box-btn text" style="font-size: 18px;  padding: 10px 24px;   background-color: white; 
                                        color: black; 
                                        border: 2px solid #4CAF50;">{{item.name}}</a>
                                </div>
                                <div class="col-4">
                                    <a class="box-btn text" style="font-size: 20px; padding: 10px 24px;"
                                        href="tel:+91{{item.number}}">Call</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>