import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  submitform:FormGroup;
  submitted: boolean = false;
  showSpinner: boolean = false;
  constructor(private formBuilder: FormBuilder,private service: ServiceService, private router: Router) {

    this.submitform = this.formBuilder.group({
name:[''],
email:['',[Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
phonenumber:[''],
subject:[''],
msg:[''],
    })
   }

  ngOnInit(): void {
  }
  submit() {
  
    if (this.submitform.invalid) {    
        alert('Please Fill the Empty fields')
      return;
    } else {
      this.submitted = false;
      var data = {
        name: this.submitform.value.name,
        email: this.submitform.value.email,
        phonenumber: this.submitform.value.phonenumber,
        subject: this.submitform.value.subject,
        msg: this.submitform.value.msg,
      }
      // //console.log(data);
      this.service.addcontactus(data).subscribe((res:any )=> {
        if (res.status == 200) {
          this.submitform.reset();  
          this.showSpinner = false;             
          Swal.fire('Success')      
        } else {
          alert('Invalid Data')
        }
      });
    }
  }
  numericOnly(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
}
