<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg12">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Privacy Policy</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div>
</div>

<div class="privacy-policy-area ptb-100" style="padding-top: 25px;">
    <div class="container">
        <div class="single-content" style="text-align: justify;">
            <h3> <b>&#10132;&nbsp;&nbsp;PRIVACY POLICY</b></h3>
            <p><b> We are committed to protecting the privacy of our users. This privacy policy explains how we collect,
                    use, and share your personal information when you use our website .</b></p>
            <p><b> Information we collect When you use our website, we may collect the following types of information .
                </b></p>
        </div>
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;Personal information</b></h3>
            <p><b>such as your name, email address, and phone number, which you provide to us when you create an account
                    or
                    make a booking . </b></p>

        </div>
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;Payment information</b></h3>
            <p><b>such as your credit card details, which you provide to us when you make a payment for a service.</b>
            </p>

        </div>
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;Usage information</b></h3>
            <p><b>such as your IP address, browser type, and operating system, which we collect automatically when you
                    use
                    our website .</b></p>

        </div>
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;Cookies</b></h3>
            <p><b>we may use cookies to track your use of our website and to personalize your experience.</b></p>

        </div>
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;How we use your information</b></h3>
            <b>
                <p>We may use your personal information for the following purposes . </p>
                <p> To provide you with our services, including processing your bookings and payments . </p>
                <p> To communicate with you about your bookings and our services . </p>
                <p> To personalize your experience on our website . </p>
                <p> To improve our website and services . </p>
                <p> To comply with legal obligations . </p>
                <p>Sharing your information We may share your personal information with third parties . </p>
            </b>

        </div>
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;Service providers</b></h3>
            <p><b>such as payment processors and booking systems.</b></p>

        </div>
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;Law enforcement</b></h3>
            <p><b>If we are required to do so by law or if we believe that such action is necessary to comply with legal
                    obligations .</b></p>

        </div>
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;Other users</b></h3>
            <p><b>If you book a service that requires sharing with other users, such as a group tour.</b></p>
            <p><b> Security of your information We take reasonable measures to protect your personal information from
                    unauthorized access, use, or disclosure. However, no method of transmission over the Internet or
                    method
                    of electronic storage is 100% secure . </b></p>

        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>