import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  getallviechle: any;
  submitform: FormGroup;
  now: any;
  submitted = false;
  bookfrom: FormGroup;
  form: FormGroup;
  todayDate: any;
  cars: any;
  car_id: any;
  chatlist: any;
  constructor(private service: ServiceService, private formBuilder: FormBuilder, private router: Router) {

  }
  get f() { return this.submitform.controls; }

  ngOnInit(): void {
    this.getlocations();
    this.bookfrom = this.formBuilder.group({
      date: [''],
      from: [''],
      to: [''],

      noofperson: [''],
      cartype: [''],
      location_id: ['']
    });

    this.form = this.formBuilder.group({
      date: [''],
      from: [''],
      to: [''],
      noofperson: [''],
      cartype: [''],
      location_id: ['']
    });

    const datePipe = new DatePipe('en-Us');
    this.now = datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  getallviechles() {
    this.service.getallviechles().subscribe((res: any) => {
      this.getallviechle = res.data;
    })
  }

  numericOnly(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  getItems() {

    if (localStorage.getItem('usr_id') == "" || localStorage.getItem('usr_id') == null) {
      alert('please Login')
    } else {
      if (this.form.invalid) {
        alert('Please Fill the Empty fields')
        return;
      } else {
        this.request() ;
        this.car_id = this.form.value.cartype
        this.service.getappvehicles().subscribe((res: any) => {
          if (res.status == 200) {
            this.getallviechle = res.data.filter((a: any) => {
              if (a.veh_id == this.car_id) {
                return a;
              }
            })
            if (this.getallviechle.length == 0) {
              alert('Vehicles Not Available')
            } else {
              this.chatlist.scrollToBottom();
              window.scroll({
                top: 50,
                left: 500,
                behavior: 'smooth'
              });
            }
          }
        })
      }
    }
  }
  login: boolean = false;

  request() {
    if ((localStorage.getItem('usr_id')) == null) {
      this.login = false;
      alert('please login to continue');
      this.router.navigate(['/login-register']);
    } else {
      this.login = true;
      if (this.form.invalid) {
        return;
      } else {
        var data = {
          usr_id: localStorage.getItem('usr_id'),
          date: this.form.value.date,
          from: this.form.value.from,
          to: this.form.value.to,
          noofperson: this.form.value.noofperson,
          cartype: this.form.value.cartype
        }
        this.service.postbookvehiclenow(data).subscribe((res: any) => {
          if (res.status == 200) {
          } else {
            alert('server down')
          }
        })
      }
    }
  }

  locationsss: any;
 
  getlocations() {
    this.service.gethotelslocation().subscribe((res: any) => {
      this.locationsss = res.data;
    })
  }
}



