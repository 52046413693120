<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg10">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Gallery</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
            
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Gallery</li>
            </ul>
        </div>
    </div>
</div>
<br>
<div class="blog-area ">
    <div class="container">
        <div class="section-title text-center">
            <!-- <span>Gallery</span> -->
            <h2>Latest Images <b>Post</b></h2>
        </div>
       
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6"
                *ngFor="let item of gallerydata | paginate: { itemsPerPage: 6, currentPage: page }">
                <div class=" blog-card">
                    <a routerLink="/listing-details/{{item.id}}"><img src="{{item.image}}" alt="Images"
                            style="width: 650px; height: 365px;"></a>
                    <div class="content" style="height: 110px;">
                        <h3>
                            <a routerLink="/listing-details/{{item.id}}" style="text-align: center;">{{item.name}}</a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-center pt-100 ">
    <pagination-controls class="my-pagination" (pageChange)="page = $event" responsive="true" previousLabel="< Prev" nextLabel="Next >">
    </pagination-controls>
</div> 
<app-footer-style-two></app-footer-style-two>
