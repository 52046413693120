<app-navbar-style-one></app-navbar-style-one>

<br><br><br>

<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Login/Register</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Login/Register</li>
            </ul>
        </div>
    </div>
</div>
<div class="user-area">
    <div class="container-fluid m-0">
        <div class="row align-items-center">
            <div class="col-lg-7 col-xl-6  p-0">
                <div class="user-img">
                    <img src="assets/img/bg (4).png" alt="Images">
                </div>
            </div>
            <div class="col-lg-5 col-xl-6">
                <div class="user-section text-center">
                    <div class="user-content">
                        <h2>Welcome <b>To ArakuTourismart.Com</b></h2>
                    </div>
                    <div class="tab user-tab">
                        <ul class="tabs">
                            <li><a href="#"> <i class="flaticon-contact"></i> Forget Password</a></li>
                            <!-- <li><a href="#"> <i class="flaticon-verify"></i> Register</a></li> -->
                        </ul>
                        <div class="tab_content current active">
                            <div class="tabs_item current">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form [formGroup]="forgetform">
                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" formControlName="number" id="name"
                                                            class="form-control" required maxlength="10" minlength="10"
                                                            placeholder="Phonenumber">
                                                    </div>
                                                </div>
                                                <div class="col-12" *ngIf="otpfiledstatus">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt'></i>
                                                        <input class="form-control" type="text" formControlName="otp"
                                                            placeholder="OTP" maxlength="4" minlength="4">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 text-center" *ngIf="!passinput">
                                                    <button type="submit" class="default-btn  user-all-btn"
                                                        *ngIf="!otpfiledstatus" (click)="logindata();">Request
                                                        Otp</button>
                                                    <button type="submit" class="default-btn  user-all-btn"
                                                        *ngIf="otpfiledstatus   " (click)="submitotp();">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab_content current active">
                        <div class="tabs_item current">
                            <div class="user-all-form">
                                <div class="contact-form">
                                    <form [formGroup]="passwordform" *ngIf="passinput">
                                        <div class="col-lg-12 ">
                                            <div class="form-group">
                                                <i class='bx bx-user'></i>
                                                <input type="text" formControlName="password" id="name"
                                                    class="form-control" required maxlength="16" minlength="8"
                                                    placeholder="Password">
                                            </div>
                                        </div>
                                        <div class="col-lg-12 ">
                                            <div class="form-group">
                                                <i class='bx bx-user'></i>
                                                <input type="text" formControlName="cpassword" id="name"
                                                    class="form-control" required maxlength="16" minlength="8"
                                                    placeholder="Re-Enter Password">
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 text-center">
                                            <button type="submit" class="default-btn  user-all-btn"
                                                (click)="forgetpassword();">Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>