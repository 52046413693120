<app-navbar-style-one></app-navbar-style-one>
<br><br><br><br>

<div class="container" *ngIf="paymentdetails.status=='SENT_FOR_CAPTURE'">
    <div style="text-align: center;">
        <div class="card">
            <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;">
                <i class="checkmark">✓</i>
            </div>
            <h1>Success</h1>
            <p>Payment successfully<br>You will receive a WhatsApp message regarding the status. <br>
                Thank you <br> Arakutourismart.com</p>
        </div>
    </div>
    <div class="mt-4" style="text-align:center;color:#000">
        <div class="row text-600 text-white bgc-default-tp1 py-25">
            <div class="col-9 col-sm-5">Details</div>
            <div class="d-none d-sm-block col-4 col-sm-2"></div>
            <div class="d-none d-sm-block col-sm-2"></div>
            <div class="col-2"></div>
        </div>
        <div class="text-95 text-secondary-d3">
            <div class="row mb-2 mb-sm-0 py-25">
                <div class="col-9 col-sm-5">Full Name</div>
                <div class="d-none d-sm-block col-2"></div>
                <div class="d-none d-sm-block col-2 text-95"></div>
                <div class="col-2 text-secondary-d2">{{customerdetails.name}}</div>
            </div>
            <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                <div class="col-9 col-sm-5">Email</div>
                <div class="d-none d-sm-block col-2"></div>
                <div class="d-none d-sm-block col-2 text-95"></div>
                <div class="col-2 text-secondary-d2">{{customerdetails.email}}</div>
            </div>
            <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                <div class="col-9 col-sm-5">Check-In Date </div>
                <div class="d-none d-sm-block col-2"></div>
                <div class="d-none d-sm-block col-2 text-95"></div>
                <div class="col-2 text-secondary-d2">{{customerdetails.fromdate | date}}</div>
            </div>
            <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                <div class="col-9 col-sm-5">Check-Out Date</div>
                <div class="d-none d-sm-block col-2"></div>
                <div class="d-none d-sm-block col-2 text-95"></div>
                <div class="col-2 text-secondary-d2">{{customerdetails.todate | date}}</div>
            </div>
        </div>
        <div class="row border-b-2 brc-default-l2"></div>
        <div class="row mt-3">
            <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0"></div>
            <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                <div class="row my-2 align-items-center bgc-primary-l3 p-2">
                    <div class="col-7 text-right">Total Amount : </div>
                    <div class="col-5"><span class="text-150 text-success-d3 opacity-2">Rs .
                            {{customerdetails.amount}}</span></div>
                </div>
            </div>
        </div>
        <hr>
    </div>
</div>


<div class="container" *ngIf="paymentdetails.status!='SENT_FOR_CAPTURE'">
    <div style="text-align: center;">
        <div class="card">
            <div style="border-radius:200px; height:200px; width:200px; background: #faf5f5; margin:0 auto;">
                <i class="checkmark f">X</i>
            </div>
            <h1 class="checkmark f">Failed</h1>
            <p>Payment Failed <br>
                If any amount has been deducted, please call us, or e-mail us.<br>
                Thank you <br> Arakutourismart.com
            </p>

        </div>
    </div>
    <br>
</div>

<app-footer-style-two></app-footer-style-two>