import { Component } from '@angular/core';
import { ServiceService } from 'src/app/pages/service.service';

@Component({
  selector: 'app-campingtents',
  templateUrl: './campingtents.component.html',
  styleUrls: ['./campingtents.component.scss']
})
export class CampingtentsComponent {
  camping: any
  constructor(private service: ServiceService) {

  }

  ngOnInit(): void {
    this.gatcampingtents();
  }

  gatcampingtents() {
    this.service.gatcampingtents().subscribe((res: any) => {
      // //console.log(res);
      this.camping = res.data;
      // //console.log(this.camping);

    })
  }
}
