import { Component } from '@angular/core';
import { ServiceService } from 'src/app/pages/service.service';

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss']
})
export class DestinationComponent {
  distination: any
  finalarray:any
  constructor(private service: ServiceService) {

  }
  ngOnInit(): void {
    this.getdistination();
  }
  getdistination() {
    this.service.getdistination().subscribe((res: any) => {
      this.distination = res.data;
      this.finalarray = [];
      for (var i = 0; i < this.distination.length; i++) {
      this.finalarray.push({ 'i': this.distination[i].id, 'name': this.distination[i].name, 'price': this.distination[i].price, 'subtitle': this.distination[i].subtitle,'description': this.distination[i].description, 'address': this.distination[i].address, 'locationurl': this.distination[i].locationurl, 'image': this.distination[i].image, 'time':this.distination[i].time,});
      }
    })
  }
}