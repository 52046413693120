import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  submitform:FormGroup;
  name: any;
  number: any;
  email: any;
  constructor(private formBuilder: FormBuilder, private service: ServiceService, private router: Router) {

    this.name = localStorage.getItem('name');
    this.number = localStorage.getItem('mobileno');
    this.email = localStorage.getItem('email');

    this.submitform = this.formBuilder.group({
      name: ['',],
      number: ['',],
      email: ['',],
    
    })

  }

  ngOnInit(): void {
    this.get()
  }

  getticketsdata: any;

  nohistory: boolean = false;
  get() {
    var data = {
      usr_id: localStorage.getItem('usr_id')
    }
    this.service.getticketshistory(data).subscribe((res: any) => {
      this.getticketsdata = res.data
      console.log(res.data);
      
      if (res.data.length == 0) {
        this.nohistory = true;
      } else {
        this.nohistory = false;
      }
      if (res.status == 200) {
      } else {
        alert('server down')
      }
    },
      error => {
      })
  }

  logout() {
    Swal.fire({
      title: 'Are you sure ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Logout Sucessfully')
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.getlgstatus('2')
      }
    })
  }




}

