<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blog</h3>
            <ul>
                <li><a href="index.html">Home</a></li>               
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>
<br>

<div class="blog-area ">
    <div class="container">
        <div class="section-title text-center">
            <span>Blog</span>
            <h2>Latest Blog <b>Post</b></h2>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/blog/Burra-caves.png" alt="Images">
                    <div class="content">
                        <span>April 19, 2020 </span>
                        <h3>Borra Caves Located in Araku Valley</h3>                         
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                 <div class="blog-card">
                    <img src="assets/img/blog/kailashgiri.png" alt="Images">
                    <div class="content">
                        <span>June 29, 2020 </span>
                        <h3>Kailasha giri Located in Visakhapatnam</h3>                        
                    </div>
                 </div>
             </div>
             <div class="col-lg-4 col-md-6">
                 <div class="blog-card">
                    <img src="assets/img/blog/Chaparai-water-falls.png" alt="Images">
                    <div class="content">
                        <span>July 15, 2020 </span>
                        <h3>chaparai Water falls Located in Araku valley</h3>                         
                    </div>
                 </div>
             </div>
             <div class="col-lg-4 col-md-6">
                 <div class="blog-card">
                    <img src="assets/img/blog/Katiki-water-falls.png" alt="Images">
                    <div class="content">
                        <span>May 14, 2020 </span>
                        <h3>Water falls located in Araku valley </h3>                        
                    </div>
                 </div>
             </div>
             <div class="col-lg-4 col-md-6">
                 <div class="blog-card">
                 <img src="assets/img/blog/Tribal-museium.png" alt="Images">
                    <div class="content">
                        <span>March 14, 2020 </span>
                        <h3>Tribal Meseum Located in araku valley</h3>                         
                    </div>
                 </div>
             </div>
             <div class="col-lg-4 col-md-6">
                 <div class="blog-card">
                   <img src="assets/img/blog/Ananthagiri-hills.png" alt="Images">
                    <div class="content">
                        <span>January 22, 2020</span>
                        <h3>Hills station Located in Araku Valley</h3>                         
                    </div>
                 </div>
             </div>
             <div class="col-lg-12 col-md-12">
                 <div class="pagination-area text-center">
                    <a routerLink="/blog" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog" class="page-numbers">2</a>
                    <a routerLink="/blog" class="page-numbers">3</a>
                    <a routerLink="/blog" class="page-numbers">4</a>
                    <a routerLink="/blog" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                 </div>
             </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>