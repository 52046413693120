import { Component } from '@angular/core';
import { ServiceService } from 'src/app/pages/service.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent {
  package: any = [];
  form_ind: any;
  constructor(private service: ServiceService) {

  }
  ngOnInit(): void {
    this.getpackages();
  }

  formshow(id: any) {
    this.form_ind = id
  }




  getpackages() {

    this.service.getpricepackages().subscribe((res: any) => {
      this.package = res.data

    })
  }

  submit(main, submain) {

    if (localStorage.getItem('usr_id') == "" || localStorage.getItem('usr_id') == null) {
      alert('please Login')
    } else {
      var data = {
        usr_id: localStorage.getItem('usr_id'),
        name: localStorage.getItem('name'),
        mobileno: localStorage.getItem('mobileno'),
        email: localStorage.getItem('email'),
        packagetype: main,
        packagename: submain,
      }
      this.service.postpackagerequest(data).subscribe((res: any) => {
        if (res.status == 200) {
          alert('Request Taken We Will reach soon ..!')
        } else {
          alert('server down')
        }
      })
    }
  }
}