<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg12">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Cancellation Policy</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Cancellation Policy</li>
            </ul>
        </div>
    </div>
</div>

<div class="privacy-policy-area ptb-100" style="padding-top: 25px;">
    <div class="container">
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;Cancellation Policy</b></h3>
            <p>
                <b>
                    We understand that unexpected changes can impact travel plans, and our policies are thoughtfully
                    designed to offer flexibility while upholding our commitment to delivering exceptional service. We
                    appreciate your choice of Araku Tourism as your preferred destination and eagerly look forward to
                    creating an unforgettable experience for you.
                </b>
            </p>
            <p>
                <b>
                    During the period from October to February, including festive seasons:
                    Cancellation Policy:
                </b>
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp; 1 .Cancellation 10 days or more before the check-in date: A full refund will be provided. If payment was
                made via Credit/Debit card, a 3% cancellation fee will be deducted.
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp; 2 .Cancellation 9 to 5 days before the check-in date: A charge of 10% of the booking amount will apply.
                If payment was made via Credit/Debit card, a 3% cancellation fee will be deducted.
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp; 3. Cancellation 4 to 3 days before the check-in date: A charge of 25% of the booking amount will apply.
                If payment was made via Credit/Debit card, a 3% cancellation fee will be deducted.
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp; 4. Cancellation 48 hours before the check-in date: A charge of 40% of the booking amount will apply. If
                payment was made via Credit/Debit card, a 3% cancellation fee will be deducted.
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp; 5. Cancellations less than 48 hours before the check-in date: No refund will be provided.
            </p>
        </div>
        <div class="single-content" style="text-align: justify;">
            <p>
                <b>
                    Cancellation Policy for the rest of the time:
                </b>
            </p>
            <p>
                <b>
                    During the period from October to February, including festive seasons:
                    Cancellation Policy:
                </b>
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;
                1. Cancellation 48 hours or more before the check-in date: A full refund will be issued. If payment was
                made via Credit/Debit card, a 3% cancellation fee will be deducted.
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;
                2 . Cancellation within 48 hours of the check-in date: A charge of 30% of the booking amount will apply.
                If payment was made via Credit/Debit card, a 3% cancellation fee will be deducted.
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;
                3. No shows or cancellations after check-in: No refund will be provided.
            </p>
            <p>
                <b>
                    NOTE : Total advance paid will be retained if cancelled anytime between 10 Days to One Month.
                </b>
            </p>

        </div>
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;Refund Policy</b></h3>
            <p><b>We offer refunds in accordance with our cancellation policy. If you are entitled to a refund, we will
                    process it as soon as possible, but please allow up to 14 days for the refund to be processed.</b>
            </p>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>