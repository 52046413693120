import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ListingComponent } from './components/pages/listing/listing.component';
import { ListingDetailsComponent } from './components/pages/listing-details/listing-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { HttpClientModule } from '@angular/common/http';
import { BookingformComponent } from './components/pages/bookingform/bookingform.component';
import { DhmisadanceComponent } from './components/pages/dhmisadance/dhmisadance.component';
import { PackagesComponent } from './components/pages/packages/packages.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DestinationComponent } from './components/pages/destination/destination.component';
import { CampingtentsComponent } from './components/pages/campingtents/campingtents.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ImageSliderModule } from './image-slider/image-slider.module';
import { NgImageSliderModule } from "ng-image-slider";
import { SlickCarouselModule } from 'ngx-slick-carousel';
// import { NgxScrollTopModule } from 'ngx-scrolltop';
import { TabsModule } from 'ngx-tabset';
import { CancellationComponent } from './components/pages/cancellation/cancellation.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSelectModule } from '@angular/material/select';
import { BestwayComponent } from './components/pages/bestway/bestway.component';
import { PaymentstatusComponent } from './components/pages/paymentstatus/paymentstatus.component';
@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HomeOneComponent,
    NavbarStyleOneComponent,
    FooterStyleTwoComponent,
    AboutComponent,
    TeamComponent,
    PricingComponent,
    CategoryComponent,
    TestimonialsComponent,
    LoginRegisterComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    ComingSoonComponent,
    ListingComponent,
    ListingDetailsComponent,
    BlogComponent,
    ContactComponent,
    BookingformComponent,
    DhmisadanceComponent,
    PackagesComponent,
    DestinationComponent,
    CampingtentsComponent,
    ProfileComponent,
    CancellationComponent,
    BestwayComponent,
    PaymentstatusComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    HttpClientModule,
    ReactiveFormsModule,
    CarouselModule,
    SlickCarouselModule,
    FormsModule, 
    NgbModule,
    NgImageSliderModule,

    TabsModule, CommonModule, BrowserAnimationsModule,
    MatSelectModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
