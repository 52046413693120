<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Best Way</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Best Way</li>
            </ul>
        </div>
    </div>
</div>
<br>

<div class="about-area ">
    <div class="container">
        <div class="row " *ngFor="let item of about">
            <h2 style="text-align: center;"><b><u>Best Way to Araku Visit ?</u></b></h2>
            <div class="col-12 col-lg-12 ">
                <p style="text-align: justify;font-weight: bold;">Araku Valley is one of the best tourist places in Andhra
                    Pradesh, undoubtedly. Not being far from Vizag, most people
                    who visit Vizag generally spend a day or two in Araku. Some end their vacation in Araku
                    while many start their
                    Andhra trip from Araku. It all depends on your preference and timing. Whatever you decide,
                    it’s crucial to know how
                    to reach this place especially if you’re visiting for the first time. You can get here
                    through trains, buses, or
                    flights. The most convenient option is the railway while airways are expensive but quicker.
                    To your surprise,
                    there’s no direct connectivity to Araku through trains and flights. So how can you get here?
                    Sit back and continue
                    to read further to know more.
                </p>
                <br><br>
            </div>
            <div class="col-12 col-lg-6 ">
                <h2 style="text-align: center;"><b><u>By Train</u></b></h2>
                <p style="text-align: justify;font-weight: bold;"> Being a hill station, reaching Araku directly from anywhere in
                    India is not an option. You have to get here via
                    Vizag. But the good things is that Vizag has brilliant train connectivity from Mumbai,
                    Delhi, Chennai, Kolkata,
                    Bengaluru, Ahmedabad, Vadodara, and other prominent cities in India. And Araku from Vizag is
                    hardly 130 km, — that
                    means in just 3 hours from Vizag. So train travel isn’t that inconvenient. There are 3
                    trains from Vizag to Araku
                    that run daily at different times. A special train Vistadome Glass-roofed Train (Number
                    08517) offers a stunning
                    view from inside as the train-roof is made up of glass. The train passes through 65 tunnels
                    and hilly areas during
                    its journey so you can get a stunning view of the amazing valleys.
                </p>
            </div>
            <div class="col-12 col-lg-6">
                <div class="about-img">
                    <img style="height:332px;width:600px;border-radius:2%"
                     src="assets/img/logo/train.jfif" alt="image">
                </div>
            </div>
            <div class="col-12 col-lg-6 ">
                <h2 style="text-align:center;"><b><u>By Road</u></b></h2>
                <p style="text-align: justify;font-weight: bold;"> Being a hill station, reaching Araku directly from anywhere in
                    India is not an option. You have to get here via
                    Vizag. But the good things is that Vizag has brilliant train connectivity from Mumbai,
                    Delhi, Chennai, Kolkata,
                    Bengaluru, Ahmedabad, Vadodara, and other prominent cities in India. And Araku from Vizag is
                    hardly 130 km, — that
                    means in just 3 hours from Vizag. So train travel isn’t that inconvenient. There are 3
                    trains from Vizag to Araku
                    that run daily at different times. A special train Vistadome Glass-roofed Train (Number
                    08517) offers a stunning
                    view from inside as the train-roof is made up of glass. The train passes through 65 tunnels
                    and hilly areas during
                    its journey so you can get a stunning view of the amazing valleys.
                </p>
            </div>
            <div class="col-12 col-lg-6">
                <div class="about-img">
                    <img style="height:332px;width:600px;border-radius: 2%;"
                     src="assets/img/logo/road.jfif" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<app-footer-style-two></app-footer-style-two>