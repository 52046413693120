import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ListingComponent } from './components/pages/listing/listing.component';
import { ListingDetailsComponent } from './components/pages/listing-details/listing-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { BookingformComponent } from './components/pages/bookingform/bookingform.component';
import { DhmisadanceComponent } from './components/pages/dhmisadance/dhmisadance.component';
import { PackagesComponent } from './components/pages/packages/packages.component';
import { DestinationComponent } from './components/pages/destination/destination.component';
import { CampingtentsComponent } from './components/pages/campingtents/campingtents.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { CancellationComponent } from './components/pages/cancellation/cancellation.component';
import { BestwayComponent } from './components/pages/bestway/bestway.component';
import { PaymentstatusComponent } from './components/pages/paymentstatus/paymentstatus.component';


const routes: Routes = [
    { path: '', component: HomeOneComponent },
    { path: 'about', component: AboutComponent },
    { path: 'team', component: TeamComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'category', component: CategoryComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    { path: 'login-register', component: LoginRegisterComponent },
    { path: 'terms-condition', component: TermsConditionComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'cancellation', component: CancellationComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'listing', component: ListingComponent },
    { path: 'listing-details/:id', component: ListingDetailsComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'bookingform', component: BookingformComponent },
    { path: 'dhmisadance', component: DhmisadanceComponent },
    { path: 'packages', component: PackagesComponent },
    { path: 'destination', component: DestinationComponent },
    { path: 'campingtents', component: CampingtentsComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'bestway', component: BestwayComponent },
    { path: 'paymentpage', component: PaymentstatusComponent },
    // Here add new pages component

    { path: '**', component: HomeOneComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }