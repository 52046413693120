import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {

  submitform: any;
  passwordform: FormGroup;
  submitted: boolean = false;
  showSpinner: boolean = false;

  constructor(private formBuilder: FormBuilder, private service: ServiceService, private router: Router) {

  }

  ngOnInit(): void {

    this.submitform = this.formBuilder.group({
      name: ['', [Validators.required]],
      number: ['', [Validators.required, Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      city: ['', [Validators.required]],
      pincode: ['', [Validators.required, Validators.maxLength(6)]],
      state: [''],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
      dpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
      gender: ['', [Validators.required]],
    })


    this.passwordform = this.formBuilder.group({
      phonenumber: ['', [Validators.required, Validators.maxLength(10)]],
      password: ['', [Validators.required,]],
    })

  }
  get we() { return this.submitform.controls; }

  postsignup() {
    this.submitted = true;
    this.showSpinner = true;
    if (this.submitform.invalid) {
      this.showSpinner = false;
      return;
    } else if (this.submitform.value.password != this.submitform.value.dpassword) {
      Swal.fire("Passwords are Unmatched")
      this.showSpinner = false;
    } else {
      this.service.postsignup(this.submitform.value).subscribe((res: any) => {
        if (res.status == 250) {
          Swal.fire('Number Already exists please login');
          this.showSpinner = false;
          this.submitform.reset();
        } else {
          Swal.fire('Submit Successfully')
          this.submitform.reset();
          this.showSpinner = false;
          Swal.fire('please login to continue')
          this.router.navigate(['/login-register']);
        }
      },
        error => {
        })
    }
  }

  Submitlogindata: boolean = false;
  passlogin() {
    this.Submitlogindata = true;
    this.showSpinner = true;
    if (this.passwordform.value.phonenumber == '') {
      Swal.fire("Please Enter the phonenumber");
      this.showSpinner = false;
    } else if (this.passwordform.value.password == '') {
      Swal.fire("Please Enter the password");
      this.showSpinner = false;
    } else {
      console.log(this.passwordform.value);
      this.service.passwordlogin(this.passwordform.value).subscribe((res: any) => {
        if (res.status == 250) {
          this.showSpinner = false;
          alert('This number not Registered ... ')
        } else if (res.status == 600) {
          this.showSpinner = false;
          alert('Wrong Password ... ')
        } else {
          alert('Login Success...')
          localStorage.setItem('usr_id', res.data[0].id);
          localStorage.setItem('mobileno', res.data[0].number);
          localStorage.setItem('name', res.data[0].name);
          localStorage.setItem('email', res.data[0].email);
          this.router.navigate(['/home ']);
          this.service.getlgstatus('1')
          this.showSpinner = false;
        }
      })
    }
  }

  // Accept Input As a Number Only
  numericOnly(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }



}
