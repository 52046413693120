import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {

  name: any;
  usr_id: any;

  constructor(private service: ServiceService, private router: Router) { }

  ngOnInit(): void {
    this.usr_id = localStorage.getItem('usr_id');
    this.checklogin();

  }


  login: boolean = true;
  notlogin: boolean = false;
  notlogins; boolean = false;

  ngAfterContentInit() {
    this.service.getloginstatus.subscribe((res: any) => {
      //console.log(res)
      if (res == '1') {
        this.login = false;
        this.notlogin = true;
        this.name = localStorage.getItem('name');
      } else if (res == '2') {
        this.login = true;
        this.notlogin = false;
      }
    })
  }


  test() {
    if (localStorage.getItem('name') == null) {
      alert('please login to continue');
      this.router.navigate(['/login-register']);

    }
    else {

    }
  }

  test2() {
    if (localStorage.getItem('name') == null) {
      alert('please login to continue');
      this.router.navigate(['/login-register']);

    }
    else {

    }
  }
  test3() {
    if (localStorage.getItem('name') == null) {
      alert('please login to continue');
      this.router.navigate(['/login-register']);

    }
    else {

    }
  }
  test4() {
    if (localStorage.getItem('name') == null) {
      alert('please login to continue');
      this.router.navigate(['/login-register']);

    }
    else {

    }
  }

  checklogin() {
    if ((localStorage.getItem('usr_id')) == null) {
      this.login = true;
    } else {
      this.login = false;
      this.notlogin = true;
      this.name = localStorage.getItem('name');
    }
  }

  logout() {
    //console.log('hello');

    Swal.fire({
      title: 'Are you sure ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire('Logout Sucessfully')
        // alert('logout successfully')
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.getlgstatus('2')
      }
    })
  }

  
  @ViewChild('audioPlayer') audioPlayer: any;
  audioUrl: string = 'https://arakutourismart.com/audio/araku.mp3';
  isPlaying: boolean = false;

  togglePlayback() {
    if (this.audioPlayer.nativeElement.paused) {
      this.audioPlayer.nativeElement.play();
      this.isPlaying = true;
    } else {
      this.audioPlayer.nativeElement.pause();
      this.isPlaying = false;
    }
  }



}


