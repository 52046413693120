import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';
@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  forgetform: FormGroup;
  passwordform:FormGroup;
  otpfiledstatus: boolean = false;
  passinput: boolean = false
  submitted: boolean = false;
  navCtrl: any;
  navigate: any;

  constructor(private serv : FormBuilder ,private service: ServiceService,private router: Router) { 
  this.forgetform = this.serv.group({
    number: ['', [Validators.required]],
    otp: [''],
  });
  this.passwordform = this.serv.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    cpassword: ['', [Validators.required, Validators.minLength(8)]],
  })
}
  ngOnInit(): void {
  }
  logindata() {
    if (this.forgetform.invalid) {
      alert("Please Enter Valid Mobile Number");
      return;
    } else {
      if (this.otpfiledstatus == false) {
        this.service.checkloginnumber(this.forgetform.value).subscribe((res: any) => {
          if (res.status == 200) {
            this.otpfiledstatus = true;
          } else if (res.status == 300) {
            alert("Your Number not Registered");
          } else {
            alert('server error')
          }
        }, error => {
        });
      } else {
        alert('Server Error')
      }
    }
  }
  submitotp() {
    this.service.submitotp(this.forgetform.value).subscribe((res: any) => {
      if (res.data.length == 0) {
        alert('Enter Your Vaild Otp ... ')
      } else {
        alert('Please Create Password')
        this.passinput = true;
      }
    }, error => {
    });
  }
  forgetpassword() {
    this.submitted = true;
    if (this.passwordform.invalid) {
      return;
    } else {
      if (this.passwordform.value.password == this.passwordform.value.cpassword) {
        var data = {
          number: this.forgetform.value.number,
          password: this.passwordform.value.password,
        }
        this.service.forgetpassword(data).subscribe((res: any) => {
          if (res.status == 200) {
            alert('Password created Successfully');
            this.router.navigate(['/login-register'])          
          } else { 
            alert('Server Down');
          }
        }, error => {
        });
      } else {
        alert('Passwords are Not match');
      }
    }
  }
}
