<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Category</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Category</li>
            </ul>
        </div>
    </div>
</div>

<section class="category-area pt-100 pb-70">
    <div class="container">
        <div class="section-title ">
            <span>The Categories</span>
            <h2>Search <b>By Category</b></h2>
        </div>
        
        <div class="row pt-45">
            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-bake"></i></a>
                    <a routerLink="/category"><h3>Restaurant</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-hotel"></i></a>
                    <a routerLink="/category"><h3>Hotel</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-barbell"></i></a>
                    <a routerLink="/category"><h3>Fitness</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-store"></i></a>
                    <a routerLink="/category"><h3>Shops</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-event"></i></a>
                    <a routerLink="/category"><h3>Events</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-flower"></i></a>
                    <a routerLink="/category"><h3>Beauty</h3></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="city-area-two pb-70"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="section-title">
                    <span>Cities</span>
                    <h2>Explore Best Cities In Area</h2>
                    <p>Porem ipsum dolor sit ame consectetur adipisicing eli sed usmod tempor incididunt </p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="city-btn">
                    <a routerLink="/" class="default-btn">View all places <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
        
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area5.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>Fort Worth</h3></a>
                        <span>17 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area6.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>San Antonio</h3></a>
                        <span>13 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area7.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>Milwaukee</h3></a>
                        <span>13 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area8.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>United Kingdom</h3></a>
                        <span>30 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 offset-md-3 offset-lg-0">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area9.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>Turkey</h3></a>
                        <span>34 Locations</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>