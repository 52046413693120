<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Login/Register</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Login/Register</li>
            </ul>
        </div>
    </div>
</div>
<div class="user-area">
    <div class="container-fluid m-0">
        <div class="row align-items-center">
            <div class="col-lg-7 col-xl-6  p-0">
                <div class="user-img">
                    <img src="assets/img/bg (4).png" alt="Images">
                </div>
            </div>

            <div class="col-lg-5 col-xl-6">
                <div class="user-section text-center">
                    <div class="user-content">
                        <h2>Welcome <b>To ArakuTourismart.Com</b></h2>
                    </div>
                    <div class="tab user-tab">
                        <ul class="tabs">
                            <li><a href="#"> <i class="flaticon-contact"></i> Login</a></li>
                            <li><a href="#"> <i class="flaticon-verify"></i> Register</a></li>
                        </ul>
                        <div class="tab_content current active">
                            <div class="tabs_item current">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form [formGroup]="passwordform">
                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" formControlName="phonenumber" id="name"
                                                            class="form-control" required maxlength="10"
                                                            (keypress)="numericOnly($event)" minlength="10"
                                                            placeholder="Phone Number">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt'></i>
                                                        <input class="form-control" type="password" id="myInput"
                                                            formControlName="password" placeholder="Password"
                                                            maxlength="16" minlength="8">
                                                    </div>
                                                    <input style="width:15px" type="checkbox"
                                                        onclick="myFunction()">Show Password
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" class="default-btn  user-all-btn"
                                                        (click)="passlogin()"
                                                        >Login</button>
                                                </div>
                                                <div class="col-lg-7 col-sm-7">
                                                    <a class="forget" routerLink="/testimonials">Forgot Password?</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form [formGroup]="submitform">
                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" formControlName="name" class="form-control"
                                                            required placeholder="Full Name *"
                                                            [ngClass]="{'is-invalid':submitted &&we.name.errors}">
                                                        <div *ngIf="submitted && we.name.errors"
                                                            class="invalid-feedback">
                                                            <div style="color:red;" *ngIf="we.name.errors.required">
                                                                * Name is required</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='bx bxs-phone'></i>
                                                        <input type="text" name="number" formControlName="number"
                                                            class="form-control" required placeholder="Phone Number *"
                                                            (keypress)="numericOnly($event)" maxlength="10"
                                                            minlength="10"
                                                            [ngClass]="{'is-invalid': submitted && we.number.errors }">
                                                        <div *ngIf="submitted && we.number.errors"
                                                            class="invalid-feedback">
                                                            <div style="color:red;" *ngIf="we.number.errors.required">
                                                                * Number is required</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='flaticon-email-2'></i>
                                                        <input type="email" name="email" formControlName="email"
                                                            class="form-control" required
                                                            data-error="Please enter your email  *" placeholder="E-mail"
                                                            [ngClass]="{'is-invalid': submitted && we.email.errors }">
                                                        <div *ngIf="submitted && we.email.errors"
                                                            class="invalid-feedback">
                                                            <div style="color:red;" *ngIf="we.email.errors.required">*
                                                                Email
                                                                is required</div>
                                                            <div style="color:red;" *ngIf="we.email.errors.email">*
                                                                Email
                                                                must be a valid email address</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-lg-12" style="padding-bottom: 2%;">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <select class="form-control s" name="gender"
                                                            formControlName="gender"
                                                            [ngClass]="{'is-invalid': submitted && we.gender.errors }">
                                                            <option value="" disabled selected>Select gender *</option>
                                                            <option value='Male'>Male</option>
                                                            <option value="Female">Female
                                                            </option>
                                                        </select>
                                                        <div *ngIf="submitted && we.gender.errors"
                                                            class="invalid-feedback">
                                                            <div style="color:red;" *ngIf="we.gender.errors.required">*
                                                                Gender is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-lg-12">
                                                    <i class='bx bx-user'></i>
                                                    <select class="form-control s" name="state" formControlName="state">
                                                        <option value="">Select state *</option>
                                                        <option value='AndhraPradesh'>Andhra Pradesh</option>
                                                        <option value="ArunachalPradesh">Arunachal Pradesh
                                                        </option>
                                                        <option value='Assam'>Assam</option>
                                                        <option value='Bihar'>Bihar</option>
                                                        <option value='Chhattisgarh'>Chhattisgarh</option>
                                                        <option value='Goa'>Goa</option>
                                                        <option value='Gujarat'>Gujarat</option>
                                                        <option value='Haryana'>Haryana</option>
                                                        <option value='Himachal Pradesh'>Himachal Pradesh </option>
                                                        <option value='Jharkhand'>Jharkhand</option>
                                                        <option value='Karnataka'>Karnataka</option>
                                                        <option value='Kerala'>Kerala</option>
                                                        <option value='Madhya Pradesh'>Madhya Pradesh</option>
                                                        <option value='Maharashtra'>Maharashtra</option>
                                                        <option value='Manipur'>Manipur</option>
                                                        <option value='Meghalaya'>Meghalaya</option>
                                                        <option value='Mizoram'>Mizoram</option>
                                                        <option value='Nagaland'>Nagaland</option>
                                                        <option value='Odisha'>Odisha</option>
                                                        <option value='Punjab'>Punjab</option>
                                                        <option value='Rajasthan'>Rajasthan</option>
                                                        <option value='Sikkim'>Sikkim</option>
                                                        <option value='Tamil Nadu'>Tamil Nadu </option>
                                                        <option value='Telangana'>Telangana</option>
                                                        <option value='Tripura'>Tripura</option>
                                                        <option value='Uttar Pradesh'>Uttar Pradesh </option>
                                                        <option value='Uttarakhand'>Uttarakhand</option>
                                                        <option value='West Bengal'>West Bengal </option>
                                                    </select>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-home-alt'></i>
                                                        <input type="text" name="city" formControlName="city"
                                                            class="form-control" required
                                                            data-error="Please enter your city *" placeholder="City"
                                                            [ngClass]="{'is-invalid': submitted && we.city.errors }">
                                                        <div *ngIf="submitted && we.city.errors"
                                                            class="invalid-feedback">
                                                            <div style="color:red;" *ngIf="we.city.errors.required">
                                                                *City
                                                                is required</div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-code'></i>
                                                        <input type="text" formControlName="pincode" class="form-control" name="pincode"
                                                         maxlength="6"
                                                            minlength="6" (keypress)="numericOnly($event)"
                                                            data-error="Please enter your Pincode" placeholder="Pincode *"
                                                            [ngClass]="{'is-invalid': submitted && we.pincode.errors }">
                                                        <div *ngIf="submitted && we.pincode.errors"
                                                            class="invalid-feedback">
                                                            <div style="color:red;" *ngIf="we.pincode.errors.required">*
                                                                Pincode is required</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt'></i>
                                                        <input class="form-control" name="password" type="password"
                                                            formControlName="password"   placeholder="Password"
                                                            id="myInput1" maxlength="16" minlength="8"
                                                            [ngClass]="{'is-invalid': submitted && we.password.errors }">

                                                        <div *ngIf="submitted && we.password.errors" class="invalid-feedback">
                                                            <div style="color:red;" *ngIf="we.password.errors.required">
                                                                * Password must be 8 to 16 Characters</div>
                                                        </div>
                                                    </div>
                                                    <input style="width:15px" type="checkbox"
                                                        onclick="myFunction1()">Show Password
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt'></i>
                                                        <input class="form-control" type="password" id="myInput2"
                                                            formControlName="dpassword" placeholder="Password *" maxlength="16" minlength="8"
                                                            [ngClass]="{'is-invalid': submitted && we.dpassword.errors }">

                                                        <div *ngIf="submitted && we.dpassword.errors" class="invalid-feedback">
                                                            <div style="color:red;" *ngIf="we.dpassword.errors.required">
                                                                * Password must be 8 to 16 Characters</div>
                                                        </div>
                                                    </div>
                                                    <input style="width:15px" type="checkbox"
                                                        onclick="myFunction2()">Show Passwords
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 text-center">
                                                <button type="submit" (click)="postsignup()"
                                                    class="default-btn  user-all-btn">Register
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="my-overlay" *ngIf="showSpinner">
    <div class="cusloader"></div>
    <h5 style="color: white;">Please Wait...</h5>
</div>