<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg11">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Terms & Conditions</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Terms & Conditions</li>
            </ul>
        </div>
    </div>
</div>

<div class="terms-conditions-area ptb-100" style="padding-top: 25px;">
    <div class="container">
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;TERMS & CONDITIONS</b></h3>
            <p><b>These terms and conditions govern your use of our website and the services we provide. By using our
                website, you agree to be bound by these terms and conditions .</b></p>

        </div>
        <div class="single-content" style="text-align: justify;">
            <h3> <b>&#10132;&nbsp;&nbsp;Booking Process</b></h3>
            <p><b>When you book a service through our website, you agree to pay the full price of the service at the time
                of booking. Once your payment is received, we will confirm your booking by email. Please ensure that the
                information you provide during the booking process is accurate and complete .</b></p>

        </div>
        <div class="single-content" style="text-align: justify;">
            <h3><b>&#10132;&nbsp;&nbsp;Changes to Terms and Conditions</b></h3>
            <p><b>We reserve the right to change these terms and conditions at any time. Any changes will be posted on our
                website and will take effect immediately.</b></p>
            <p><b>Contact Us If you have any questions or concerns about these terms and conditions or our services, please
                contact us at [insert contact details] . </b></p>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>