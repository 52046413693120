<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="overlay"></div>


<owl-carousel-o [options]="bannerOptions">
    <ng-container *ngFor="let slide of banners">
        <ng-template carouselSlide>
            <div class="slider-item" [style.background]="'url(' + slide.image + ')'" style="width: 100%; height: 100%;">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-content">
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>
<!-- <ng-select [items]="locationsss" bindLabel="name" bindValue="id" [(ngModel)]="selectedCar"  *ngFor="let elem of locationsss" Value="elem.id">
</ng-select> -->
<div class="banner-form-area banner-form-mt">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-form">
                    <form [formGroup]="submitform">
                        <div class="row">
                            <div class="form-group col-lg-2 col-md-4">
                                <select class="form-control s" formControlName="location_id" style="color: #106164;">
                                    <option value="" selected disabled> Select Location </option>
                                    <option *ngFor="let i of locationdetails" value="{{i.id}}">{{i.location_name}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-lg-2 col-md-4" style="color: #106164;">
                                <!-- <label style="padding-right:30%;">From Date</label> -->
                                <input class="form-control" type="text" formControlName="fromdate" [min]="now" 
                                    placeholder="Check-In" onfocus="(this.type='date')" onkeydown="return false"
                                    [ngClass]="{'is-invalid': submitted && f.fromdate.errors }" style="color: #106164;">
                                <div *ngIf="submitted && f.fromdate.errors" class="invalid-feedback">
                                    <div *ngIf="f.fromdate.errors.required">Date is required</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-2 col-md-4">
                                <!-- <label style="padding-right:50%;">To Date</label> -->
                                <input class="form-control" type="text" formControlName="todate" [min]="now"
                                    onfocus="(this.type='date')" placeholder="Check-Out"  onkeydown="return false"
                                    [ngClass]="{'is-invalid': submitted && f.todate.errors }" style="color: #106164;">
                                <div *ngIf="submitted && f.todate.errors" class="invalid-feedback">
                                    <div *ngIf="f.todate.errors.required">Date is required</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-2 col-md-4">
                                <select class="form-control s" formControlName="persons" type="text"
                                    style="color: #106164;">
                                    <option value="" selected disabled>No of Adults</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>
                            </div>
                            <div class="form-group col-lg-2 col-md-4">
                                <select class="form-control s" formControlName="childerns" type="text"
                                    style="color: #106164;">
                                    <option value="" selected disabled>No.of Children</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                            <div class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0">
                                <button type="submit" class="default-btn" (click)="gotorooms()">Search hotel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <br><br>
    <br>
    <div class="place-area  ">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-6">
                    <div class="section-title mb-45">
                        <span>Desire Places</span>
                        <h2>Most Popular Places</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="place-card">
                        <a class="place-images"><img src="assets/img/place-area/popular-places-01.png" alt="Images"
                                style="border-radius: 79px;"></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="place-card">
                        <a class="place-images"><img src="assets/img/place-area/popular-places-02.png" alt="Images"
                                style="border-radius: 79px;"> </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="place-card">
                        <a class="place-images"><img src="assets/img/place-area/popular-places-03.png" alt="Images"
                                style="border-radius: 79px;"></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="place-card">
                        <a class="place-images"><img src="assets/img/place-area/popular-places-04.png" alt="Images"
                                style="border-radius: 79px;"></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="place-card">
                        <a class="place-images"><img src="assets/img/place-area/popular-places-05.png" alt="Images"
                                style="border-radius: 79px;"></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="place-card">
                        <a class="place-images"><img src="assets/img/place-area/popular-places-02.png" alt="Images"
                                style="border-radius: 79px;"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section id="advertisers" class="advertisers-service-sec pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="section-header text-center">
                    <h2 class="fw-bold fs-1">
                        Choose Reasons
                        <span class="b-class-secondary">Why Should </span>Choose Us
                    </h2>
                    <p class="sec-icon"><i class="fa-solid fa-gear"></i></p>
                </div>
            </div>
            <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
                <div class="col">
                    <div class="service-card">
                        <div class="icon-wrapper">
                            <i class="flaticon-phone-call"></i>
                        </div>
                        <h3>Mobile Friendly</h3>
                        <p style="text-align: justify;">Our user-friendly mobile application offers a variety of
                            tours and travels with just a few
                            taps, Whether solo or in a group, it's your go-to travel solution – mobile friendly app.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="service-card">
                        <div class="icon-wrapper">
                            <i class="flaticon-support"></i>
                        </div>
                        <h3>Admin Panel</h3>
                        <p style="text-align: justify;">Simplify your life with our admin panel. Update and manage
                            your packages and website content with just a few clicks.</p>
                    </div>
                </div>
                <div class="col">
                    <div class="service-card">
                        <div class="icon-wrapper">
                            <i class="flaticon-support"></i>
                        </div>
                        <h3>7:30 AM - 9:00 PM</h3>
                        <p style="text-align: justify;">No matter the time, we're always here to support you every
                            step
                            of the way. Our 24-hour
                            call
                            support ensures that you can reach us anytime with any queries or concerns.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="video-area video-area-bg">
        <div class="container">
            <div class="video-content">
                <h2>Are You Ready To Start Your Journey?</h2>
                <a href="https://www.youtube.com/watch?v=3CYGU_5XSKY" class="play-btn"><i class='bx bx-play'></i></a>
            </div>
        </div>
    </div><br>
    <div class="counter-area">
        <div class="container">
            <div class="counter-bg">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-3">
                        <div class="single-counter">
                            <h3>1000+ </h3>
                            <span>New Visitors Every Week</span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-3">
                        <div class="single-counter">
                            <h3>8000+</h3>
                            <span>New Visitors Every Month</span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-3">
                        <div class="single-counter">
                            <h3>25+</h3>
                            <span> Amazing places to visit</span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-3">
                        <div class="single-counter">
                            <h3>20+</h3>
                            <span>New Listing Next Week</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="process-area">
        <div class="process-into process-into-2">
            <div class="container">
                <div class="section-title text-center">
                    <h2><b>3 Steps away from your dream destination</b></h2>
                </div>
                <div class="row pt-45">
                    <div class="col-lg-4 col-sm-6">
                        <div class="process-item">
                            <div class="process-item-number number1">1</div>
                            <i class="flaticon-position"></i>
                            <div class="content">
                                <h3>Pick Your Dates and Destination</h3>
                                <p style="text-align: justify;"> Select your preferred travel dates and destination on
                                    our website .</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="process-item">
                            <div class="process-item-number number2 active">2</div>
                            <i class="flaticon-to-do-list"></i>
                            <div class="content">
                                <h3>Browse and Select Your Package</h3>
                                <p style="text-align: justify;">Browse through our travel packages and select the
                                    one that suits your preferences! Find the perfect travel package and book your dream
                                    vacation hassle-free!.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                        <div class="process-item">
                            <div class="process-item-number number3">3</div>
                            <i class="flaticon-box"></i>
                            <div class="content">
                                <h3>Get Ready for Your Trip</h3>
                                <p style="text-align: justify;">Pack your bags and get ready for an unforgettable trip
                                    to Araku ! Have a great trip!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="process-line-2"><img src="assets/img/shape/process-line2.png" alt="Images"></div>
        </div>
    </section>



    <div class="container-fluid">
        <div class="section-title text-center">
            <span>Testimonials</span>
            <h2>What Our Clients Say</h2>
        </div>
        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-item testimonial-item-bg">
                <h3>Srikar</h3>
                <p style="text-align: justify;">I recently went on the Araku Valley tour with Araku Tourismart, and
                    it
                    was an incredible experience.
                    The
                    tour package was well-organized and included all the must-see spot . </p>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>
            <div class="testimonial-item testimonial-item-bg">
                <h3>Chandana Rayudu</h3>
                <p style="text-align: justify;">My family and I had a fantastic time on the Araku Tourismart package
                    tour. The accommodations were
                    comfortable and the itinerary was well-planned .</p>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>
            <div class="testimonial-item testimonial-item-bg">
                <h3>Latha Reddy</h3>
                <p style="text-align: justify;">I booked a customized tour package with Araku Tourismart, and I
                    couldn't
                    be happier. The staff was
                    very
                    helpful and accommodating, and they went above and beyond to ensure that my trip was everything
                    I
                    wanted
                    it to be.</p>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>
            <div class="testimonial-item testimonial-item-bg">
                <h3>Raj kumar</h3>
                <p style="text-align: justify;">The tour was well-organized and included a variety of activities,
                    from
                    hiking to visiting local
                    markets.
                    I would highly recommend Araku Touristmart to anyone looking for an authentic.</p>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>
            <div class="testimonial-item testimonial-item-bg">
                <h3>Sanaik Tubi</h3>
                <p style="text-align: justify;"> I would definitely recommend Araku Tourismart to anyone looking for
                    a
                    unique and memorable vacation.
                </p>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="contact-area"> -->
    <!-- <div class="container"> -->
    <div class="contact-max">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-position"></i>
                    <h3> <b>Araku - Visakhapatnam Rd , Araku , Visakhapatnam , Andhra Pradesh - 531149 , India </b>.</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-email"></i>
                    <h3><a href="mailto:arakutourismart.com@gmail.com"> <b>Email</b> : <b>
                                Arakutourismart.com@gmail.com</b> </a> </h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-card">
                    <i class="flaticon-to-do-list"></i>
                    <h3><a href="tel:+(91)9441201920"> <b> Phone Number </b> : <b> +91 - 9441201920</b></a></h3>
                    <h3><a href="tel:+(91)9492822877"> <b> Phone Number </b> : <b> +91 - 9492822877</b></a></h3>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->


    <div class="application-area-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="application-content">
                        <div class="section-title">
                            <span>Download Araku Tourismart app</span>
                            <h2>Get More In Our Application Sit Back And Enjoy</h2>
                            <p style="text-align: justify;">Begin your hassle-free adventure with our user-friendly
                                app. Effortlessly browse, book, and
                                manage your itinerary. Download now and unlock endless travel possibilities . </p>
                        </div>
                        <div class="application-btn">
                            <a target="_blank"
                                href="https://play.google.com/store/apps/details?id=io.ionic.starterArukuTourismart"
                                class="application-play-btn">
                                <img src="assets/img/google-play.svg" alt="svg">
                                <div class="btn-content">
                                    <span>ANDROID APP ON</span>
                                    <h3>Google Play</h3>
                                </div>
                            </a>
                            <a target="_blank" href="https://apps.apple.com/in/app/arukutourismart/id6449212304"
                                class="application-play-btn">
                                <img src="assets/img/company.svg" alt="svg">
                                <div class="btn-content">
                                    <span>DOWNLOAD ON THE</span>
                                    <h3>App Store</h3>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="application-img-two">
                        <img src="assets/img/Araku-bwerbsite-footer-image.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- 
<ng-template #modal let-modal>
    gjfjfjfgjgjfghfj
</ng-template> -->
 
<!-- 
 <div class="container raj">
    <div class="card">
        <div class="row">
            <form [formGroup]="submitform">
                <div class="col-lg-2 col-md-4">
                </div>
            </form>
        </div>
    </div>
</div> -->



 <!-- <iframe type="audio/mp3" allow="autoplay" style="display: none" src="https://arakutourismart.com/audio/araku.mp3"></iframe> -->

<app-footer-style-two></app-footer-style-two>